import TemplateManagement from '@/module/fiab/template-management.vue';
import { Authority } from '@/shared/security/authority';

const Entities = () => import('@/entities/entities.vue');

const StudyManagement = () => import('@/module/fiab/study-management.vue');

export default [
  {
    path: '/',
    component: Entities,
    children: [
      {
        path: 'fiab/management',
        name: 'Fiability Management',
        component: StudyManagement,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'fiab/template',
        name: 'Fiability Template Management',
        component: TemplateManagement,
        meta: { authorities: [Authority.USER] },
      },
    ],
  },
];
